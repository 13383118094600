<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div>
        <div>
          <h1 class="mr-sm-4 header-tablepage">Admin detail</h1>
        </div>
        <div class="title-tabs mt-3">User Info</div>
        <b-container class="no-gutters bg-white">
          <div class="py-3">
            <b-row>
              <b-col lg="6" md="6" sm="12" xs="12">
                <InputText
                  textFloat="First Name"
                  placeholder="First Name"
                  type="text"
                  name="first_name"
                  isRequired
                  :isValidate="$v.form.firstName.$error"
                  :v="$v.form.firstName"
                  v-model="form.firstName"
                />
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <InputText
                  textFloat="Last Name"
                  placeholder="Last Name"
                  type="text"
                  name="last_name"
                  isRequired
                  :isValidate="$v.form.lastName.$error"
                  :v="$v.form.lastName"
                  v-model="form.lastName"
                />
              </b-col>
              <b-col
                lg="6"
                md="6"
                sm="12"
                xs="12"
                class="d-flex flex-column justify-content-between"
              >
                <div class="d-flex align-items-center">
                  <span class="label">
                    Email <span class="text-danger">*</span></span
                  >
                  <span
                    class="ml-auto reset-password"
                    @click="modalShow"
                    v-if="mode"
                    >Reset Password</span
                  >
                </div>
                <InputText
                  textFloat=""
                  placeholder="Email"
                  type="text"
                  name="email"
                  :isValidate="$v.form.email.$error"
                  :v="$v.form.email"
                  v-model="form.email"
                />
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12" v-if="!mode">
                <InputText
                  textFloat="Password"
                  placeholder="Password"
                  type="password"
                  name="password"
                  isRequired
                  customLabel
                  autocomplete="new-password"
                  :v="$v.form.password"
                  :isValidate="$v.form.password.$error"
                  v-model="form.password"
                  :isShowPassword="true"
                >
                  <template v-slot:textFloat>
                    <b-button size="sm" class="ml-3" @click="generatePassword()"
                      >Generate Password</b-button
                    >
                  </template>
                </InputText>
              </b-col>
              <b-col lg="6" md="6" sm="12" xs="12">
                <div class="d-flex align-items-center">
                  <span class="label">
                    Role <span class="text-danger">*</span></span
                  >
                  <span
                    class="ml-auto reset-password"
                    @click="modalRoleShow"
                    v-if="form.roleId"
                    >See More</span
                  >
                </div>

                <InputSelect
                  title=""
                  name="Role"
                  valueField="id"
                  v-model="form.roleId"
                  :options="optionsRole"
                  textField="name"
                  :v="$v.form.roleId"
                >
                  <template v-slot:option-first>
                    <b-form-select-option :value="null" disabled
                      >-- Please Select Role --</b-form-select-option
                    >
                  </template>
                </InputSelect>
              </b-col>
            </b-row>
          </div>
        </b-container>
        <div class="no-gutters bg-white mt-3 py-2 px-3">
          <b-form-checkbox
            switch
            v-model="form.status"
            class="radio-active"
            size="lg"
            :value="1"
            :unchecked-value="0"
          >
            <span class="ml-2 main-label">{{
              form.status ? "Active" : "Inactive"
            }}</span>
          </b-form-checkbox>
        </div>
        <FooterAction @submit="saveForm()" routePath="/managePermission" />
      </div>
      <ModalLoading ref="modalLoading" />
      <ModalChangePassword ref="modalChangePassword" @submit="changePassword" />
      <ModalDisplayRole
        ref="modalDisplayRole"
        :roleID="form.roleId"
        v-if="form.roleId"
      />
    </div>
  </div>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import OtherLoading from "@/components/loading/OtherLoading";
import ModalChangePassword from "@/components/modal/ModalChangePassword";
import ModalDisplayRole from "@/components/admin/ModalDisplayRole";
import ModalLoading from "@/components/modal/ModalLoading";
export default {
  name: "Detail",
  components: {
    InputText,
    InputSelect,
    OtherLoading,
    ModalChangePassword,
    ModalLoading,
    ModalDisplayRole,
  },
  validations() {
    var form = {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      password: { required, minLength: minLength(6) },
      roleId: { required },
    };
    if (this.mode) {
      form.password = {};
    }
    return {
      form,
    };
  },

  computed: {
    mode() {
      return this.$route.params.id != 0 ? true : false;
    },
  },
  watch: {},
  data() {
    return {
      isLoading: false,
      form: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        roleId: null,
        status: 1,
      },
      optionsRole: [],
      id: this.$route.params.id,
    };
  },
  created() {
    document.body.style.setProperty("--margin-bottom", "4rem");
    if (this.mode) {
      this.getAdminByID();
    }
    this.getRole();
  },
  destroyed() {
    document.body.style.setProperty("--margin-bottom", "0px");
  },
  methods: {
    modalShow() {
      this.$refs.modalChangePassword.show();
    },
    modalRoleShow() {
      this.$refs.modalDisplayRole.show();
    },
    async getAdminByID() {
      const { data } = await this.$Axios(
        `${process.env.VUE_APP_API}/Admin/${this.$route.params.id}`
      );
      this.form = data.detail;
    },
    async getRole() {
      const { data } = await this.$Axios(
        `${process.env.VUE_APP_API}/Admin/getFilterList`
      );
      this.optionsRole = data.detail;
    },
    async saveForm() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$refs.modalLoading.show();
      if (this.mode) {
        this.$Axios
          .put(`${process.env.VUE_APP_API}/Admin`, this.form)
          .then((res) => {
            if (res.data.result) {
              this.successAlert().then(() =>
                this.$router.push("/managePermission")
              );
            } else {
              this.errorAlert(res.data.message);
            }
            this.$refs.modalLoading.hide();
          })
          .catch((err) => {
            this.$swal2.fire(
              "An Error Occurred",
              "An error occurred, the data could not be saved. Please try again.",
              "error"
            );
          });
      } else {
        this.$Axios
          .post(`${process.env.VUE_APP_API}/Admin`, this.form)
          .then((res) => {
            if (res.data.result) {
              this.successAlert().then(() =>
                this.$router.push("/managePermission")
              );
            } else {
              this.errorAlert(res.data.message);
            }
            this.$refs.modalLoading.hide();
          })
          .catch((err) => {
            this.errorAlert(
              "An Error Occurred",
              "An error occurred, the data could not be saved. Please try again."
            );
          });
      }
    },
    generatePassword() {
      var chars =
        "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var passwordLength = 20;
      var password = "";
      for (var i = 0; i <= passwordLength; i++) {
        var randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber + 1);
      }

      this.form.password = password;
    },
    changePassword(val) {
      this.form.password = val;
      this.saveForm();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .custom-select {
  color: var(--font-main-color);
  border: 1px solid #bcbcbc;
  border-radius: 0px;
  padding: 5px 10px;
  height: 31px;
}
.label {
  color: var(--font-main-color);
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
.img-box-preview {
  width: 100%;
  padding-bottom: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  position: relative;
}
.reset-password {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
  font-size: 12px;
}
::v-deep h2#swal2-title.swal2-title {
  font-size: 24px !important;
}
</style>
