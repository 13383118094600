<template>
  <b-modal
    v-model="modalShow"
    hide-footer
    centered
    body-class="p-3"
    @hidden="handleCloseModal"
  >
    <template #modal-header>
      <b-row class="header-filter no-gutters">
        <b-col lg="10" class="ft-title">{{
          "Permission To Access The Menu Of " + name
        }}</b-col>
        <b-col class="text-right">
          <button
            type="button"
            aria-label="Close"
            class="close text-right"
            @click="hide"
          >
            ×
          </button>
        </b-col>
      </b-row>
    </template>
    <div>
      <ol>
        <li v-for="role of roleList" :key="role.id">
          {{ role.name }}
          <ol>
            <li
              v-for="roles of role.subCondition"
              :key="role.id + '-' + roles.id"
            >
              {{ roles.name }}
            </li>
          </ol>
        </li>
      </ol>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: { roleID: { required: true } },
  data() {
    return {
      modalShow: false,
      roleList: [],
      name: "",
    };
  },
  mounted() {},
  methods: {
    async getRoleID() {
      const { data } = await this.$Axios(
        `${process.env.VUE_APP_API}/Role/${this.roleID}`
      );
      const filter = data.detail.roleSelect.filter((el) => {
        el.subCondition = el.subCondition.filter(
          (el) => el.isCheck == 1 && el.display == 1
        );
        return el.display == 1 && el.isCheck == 1;
      });

      this.name = data.detail.name;
      this.roleList = filter;
    },
    hide() {
      this.modalShow = false;
    },
    show() {
      this.getRoleID();
      this.modalShow = true;
    },
    close() {
      this.modalShow = false;
    },
    handleOkModal() {
      this.$emit("handler", true);
      this.modalShow = false;
    },
    handleCloseModal() {
      this.$emit("handler", false);
      this.modalShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  display: block;
  background-color: var(--secondary-color);
  color: var(--font-color);
}
.ft-title {
  color: var(--font-color);
  font-weight: 600;
  font-size: 16px;
}
ol {
  counter-reset: item;
}
li {
  display: block;
}
li:before {
  content: counters(item, ".") ".";
  counter-increment: item;
}
</style>
